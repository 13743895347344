@use '@angular/material' as mat;

@property --rs-mat-drawer--sticky-top-position {
  syntax: '<length>';
  inherits: true;
  initial-value: 0;
}

@property --rs-drawer-header--padding {
  syntax: '<length>+';
  inherits: true;
  initial-value: 20px;
}

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.sidenav-overrides(
    (
      container-shape: 0,
    )
  );
}
mat-sidenav-container,
mat-drawer-container {
  margin-bottom: calc(var(--rs-footer--margin-top) * -1);
  padding-bottom: var(--rs-footer--margin-top);
}

mat-drawer-container.mat-drawer-container,
.mat-sidenav-container {
  --mat-sidenav-content-background-color: transparent;
  --mat-sidenav-container-background-color: var(--rs-color--white);
  --mat-sidenav-container-width: 450px;
  overflow: hidden;

  // Makes drawer taking the full height of viewport
  &:has(.mat-drawer[mode='over']),
  &:has(.mat-drawer[ng-reflect-mode='over']) {
    // Make sure it goes over subheader (z-index: 2) when opening
    &:has(.mat-drawer-shown),
    &.mat-drawer-container-has-open {
      z-index: 3;

      .mat-drawer,
      .mat-drawer-backdrop,
      .mat-sidenav,
      .mat-sidenav-backdrop {
        position: fixed;
      }
    }
  }

  &:has(.mat-drawer[mode='side']) {
    .mat-drawer-content {
      $footer-height: 106px;
      min-height: calc(
        100vh - (var(--rs-footer--margin-top) + $rs-top-bar-height + $rs-subheader-height + $footer-height)
      );
    }
  }

  .mat-drawer,
  .mat-sidenav {
    // Drawer header
    .rs-drawer-header,
    rs-drawer-header,
    .rs-sidenav-header {
      position: relative;
      text-align: center;
      padding: var(--rs-drawer-header--padding);
      border-bottom: 1px solid var(--rs-ds--border-color);
      margin-bottom: 20px;

      button {
        @include rs-font--primary-regular;
      }

      .rs-drawer-title,
      .rs-sidenav-title {
        @include rs-font--primary-regular;
        color: var(--rs-ds--color--primary);
        font-size: 15px;
        line-height: 19px;
        margin: 0;
        padding: 0;
      }
    }

    .rs-drawer-content,
    .rs-sidenav-content {
      margin: 0 40px;

      .rs-section-title {
        @include rs-font--primary-regular;
        font-size: 14px;
        line-height: 18px;
      }
    }

    mat-expansion-panel,
    .mat-expansion-panel {
      box-shadow: none;

      .mat-expansion-panel-header {
        height: 48px !important;
        padding-block: 0;
        padding-inline: 2px;
        box-shadow: none;

        &:hover {
          background: none !important;
        }
      }

      .mat-expansion-panel-body {
        padding: 0 0 12px 12px;
        background-color: transparent;
      }

      &.rs-expansion-panel-pre-separator:before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--rs-color--grey--50);
        margin: 8px 0 8px;
        display: block;
      }
    }
  }

  .mat-drawer-content {
    overflow: visible;
  }
}

/* [rs-sticky-drawer] => Makes mat-drawer/sidenav content sticky */
mat-drawer-container.mat-drawer-container,
.mat-sidenav-container {
  .mat-drawer,
  .mat-sidenav {
    &[rs-sticky-drawer] {
      .mat-drawer-inner-container {
        position: sticky;
        height: fit-content;
        top: var(--rs-mat-drawer--sticky-top-position);
      }
    }
  }
}
