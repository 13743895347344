@import '../maps/rs-colors';

/* Breakpoints */
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1396px;

@property --rs-container-breakpoint--lg {
  syntax: '<length>';
  inherits: false;
  initial-value: $lg;
}

$rs-top-bar-height: 75px;
$rs-subheader-height: 75px;

@property --rs-ds--border-radius {
  syntax: '<length>';
  inherits: true;
  initial-value: 12px;
}

@property --rs-ds--border-radius--small {
  syntax: '<length>';
  inherits: true;
  initial-value: 4px;
}

// rs-container => defines content's max width
@property --rs-ds--main-container--width {
  syntax: '<percentage>';
  inherits: true;
  initial-value: 100%;
}

@property --rs-ds--main-container-top-spacing {
  syntax: '<length>';
  inherits: false;
  initial-value: 40px;
}

@property --rs-ds--main-container--padding-inline {
  syntax: '<length>';
  inherits: false;
  initial-value: 16px;
}

@property --rs-ds--section--bottom-spacing {
  syntax: '<length>';
  inherits: true;
  initial-value: 40px;
}

@property --rs-ds--link--color {
  syntax: '<length>';
  inherits: true;
  initial-value: orange;
}

:root {
  --rs-ds--font--primary: 'Montserrat';
  --rs-ds--font--primary-light: 'Montserrat-light';
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;

  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;

  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);

  --rs-ds--border-color: var(--rs-color--grey--200);

  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);

  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);

  --rs-ds--link--color: var(--rs-ds--color--primary);

  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);

  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}
